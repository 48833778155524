import "@eulerxyz/euler-materials/dist/css/global.css"
import {Box, EulerMaterials, Typography} from "@eulerxyz/euler-materials";
import {Button} from "@eulerxyz/euler-materials/components";

function App() {
  return (
    <EulerMaterials.Provider>

      <Box className="App" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={8}>
        <Box textAlign="center">
            <Typography variant={{sm: "h6", xs: "subtitle1"}} component="h1">Welcome to the Euler CTF program</Typography>
            <Typography variant={{xs: "subtitle2", sm: "subtitle1"}} color="secondary">Administered by Hats Finance.</Typography>
        </Box>

          <Box display="flex" justifyContent="center" alignItems="center" gap={4} flexDirection="column">
              <Button asChild>
                  <a href="https://app.hats.finance/audit-competitions/euler-ctf-0x8899a84b1807c78db09c1ccd0812946d18986151/rewards" target="_blank">Click here to learn more and participate in the program</a>
              </Button>
              <Button asChild>
                  <a href="https://euler-hats.euler.finance/terms.pdf" target="_blank">Click here to read the Terms of Participation</a>
              </Button>
          </Box>
      </Box>
    </EulerMaterials.Provider>
  );
}

export default App;
